import { IStore, IConfig } from 'models';


export class Store {
    static state: IStore = {
        wallet_address: '',
        is_wallet_connected: false,
        hasPhantomExtension: false,
        stake_addresses: [],
        config: {
            programId: "3y2QmWKcTi25TgrCbEx37wnC8yvoPxnBzC5RQHVhMQXi",
            marketId: "BQh8qebcDc1NiNotLibfwGvsGXxuRL4NQ3L41NrAo27M",
            mintAccount: "GJYCfRMjDDGNf6UiDFT2dt1ayUX9r7CwqtNivBaKbEdP",
            tokenAccount: "5DnxjX2fmZSBiYKtR1vbp7ai11n16CLuaNtsNX3E5poD"
        },
        connectedWalletType: ''
    };

    static setWallet = (address: string) => {
        Store.state.wallet_address = address;
        Store.state.is_wallet_connected = true;
    };

    static disconnectWallet = () => {
        Store.state.wallet_address = '';
        Store.state.is_wallet_connected = false;
        Store.state.connectedWalletType = "";
    };

    static addStakeAddress = (address: string[]) => {
        Store.state.stake_addresses.push(...address);
    };

    static setConfig = (config: IConfig) => {
        Store.state.config = { ...config };
    };

    static setPhantomProvider = (value: boolean) => {
        Store.state.hasPhantomExtension = value;
    };

    static setConnectWalletType = (type: "sollet" | "phantom") => {
        Store.state.connectedWalletType = type;
    }

};
