import { AppPlaceHolder } from "components"
import { MarketplaceHook } from "hooks"
import { IMarketplace } from "models"
import { useHistory } from "react-router-dom"
import { Card, Icon, Image } from "semantic-ui-react"
import styled from "styled-components"


const Header = styled.p`
    color : ${({ theme }) => theme.palette.text};
    font-size : 1.5rem;
    font-wieght : bold;
`

interface IProps {
    item: IMarketplace;
    onClick: () => void;
}

const MarketplaceItem = (props: IProps) => (
    <Card >
        <Image src={props.item.image} wrapped ui={false} />
        <Card.Content>
            <Card.Header onClick={props.onClick}>{props.item.title}</Card.Header>
            <Card.Meta>
                <span className='date'>{props.item.author}</span>
            </Card.Meta>
            <Card.Description>
                <p>
                    Price: ${props.item.price}
                </p>
                <p>
                    Duration: {props.item.duration} Days
                </p>
                <div >
                    Down Payment: {props.item.downpayment}%
                </div>
            </Card.Description>
        </Card.Content>
        <Card.Content extra className="d-flex justify-space-between">
            <div>
                <Icon name='heart outline' />
                {props.item.like}
            </div>
        </Card.Content>
    </Card>
)

export const HomeScreen = () => {

    const { data, loading } = MarketplaceHook();
    let history = useHistory();

    const cardClick = (item: IMarketplace) => {
        history.push(`details/${item.id}`);
    }

    return (
        <div className="container">
            <div className="row my-3">
                <div className="col-12">
                    <Header>NFT Marketplace</Header>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    {loading ? <AppPlaceHolder size={16} /> : <Card.Group doubling itemsPerRow={4} stackable>
                        {data.map((item, index) => (<MarketplaceItem
                            key={item.id}
                            item={item}
                            onClick={() => cardClick(item)}
                        />
                        ))}
                    </Card.Group>
                    }
                </div>
            </div>
        </div>
    )
}