import { toast, ToastOptions } from 'react-toastify';

export class ToastMessage {
    static defaultOptions: ToastOptions = {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    }

    static success = (message: string, options?: Partial<ToastOptions>) => {
        const finalOptions: ToastOptions = Object.assign(ToastMessage.defaultOptions, { ...options || {} });
        toast.success(message, finalOptions)
    }

    static error = (message: string, options?: Partial<ToastOptions>) => {
        const finalOptions: ToastOptions = Object.assign(ToastMessage.defaultOptions, { ...options || {} });
        toast.error(message, finalOptions)
    }

    static warning = (message: string, options?: Partial<ToastOptions>) => {
        const finalOptions: ToastOptions = Object.assign(ToastMessage.defaultOptions, { ...options || {} });
        toast.warn(message, finalOptions)
    }

    static dark = (message: string, options?: Partial<ToastOptions>) => {
        const finalOptions: ToastOptions = Object.assign(ToastMessage.defaultOptions, { ...options || {} });
        toast.dark(message, finalOptions)
    }

}