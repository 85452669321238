import { WalletNotConnectedError } from "@solana/wallet-adapter-base"
import { useConnection, useWallet } from "@solana/wallet-adapter-react"
import { Keypair, SystemProgram, Transaction } from "@solana/web3.js"
import { PrimaryButton } from "elements"
import { MarketplaceByIdHook } from "hooks"
import { IMarketplace } from "models"
import { useCallback } from "react"
import { useParams } from "react-router-dom"
import { Image, Segment } from "semantic-ui-react"
import styled from "styled-components"


const Header = styled.p`
    color : ${({ theme }) => theme.palette.text};
    font-size : 1.5rem;
    font-wieght : bold;
`

interface IProps {
    item: IMarketplace | undefined;
    onClick: () => void;
}

const MarketplaceItem = (props: IProps) => (
    <div className="row justify-contents-center">
        <div className="col-12">
            <Image src={props.item?.image} size="medium" />
        </div>
        <div className="col-12 my-2">
            <Header>{props.item?.title}</Header>
        </div>
        <div className="col-12">
            <p >{props.item?.author}</p>
            <p>
                Price: ${props.item?.price}
            </p>
            <p>
                Duration: {props.item?.duration} Days
            </p>
            <p >
                Down Payment: {props.item?.downpayment}%
            </p>
        </div>
        <div className="col-12 my-2">
            <PrimaryButton onClick={props.onClick}>Approve</PrimaryButton>
        </div>
    </div>
)

interface ParamTypes {
    id: string
}

export const DetailsScreen = () => {

    let { id } = useParams<ParamTypes>();
    const { connection } = useConnection();
    const { publicKey, sendTransaction } = useWallet();
    const { data, loading } = MarketplaceByIdHook(id);

    const onClick = useCallback(async () => {
        if (!publicKey) throw new WalletNotConnectedError();

        const transaction = new Transaction().add(
            SystemProgram.transfer({
                fromPubkey: publicKey,
                toPubkey: Keypair.generate().publicKey,
                lamports: 1,
            })
        );
        const signature = await sendTransaction(transaction, connection);
        await connection.confirmTransaction(signature, 'processed');
    }, [publicKey, sendTransaction, connection]);

    return (
        <div className="container">
            <div className="row my-3 mx-0">
                <div className="col-12">
                    <Header>NFT Details</Header>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <Segment basic loading={loading}>
                        <MarketplaceItem
                            item={data}
                            onClick={onClick}
                        />
                    </Segment>
                </div>
            </div>
        </div>
    )
}