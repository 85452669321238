
import {
    Account,
    Connection,
    Transaction,
    TransactionInstruction,
} from '@solana/web3.js';
import Wallet from '@project-serum/sol-wallet-adapter';
import { COMMITMENT, ConnectedCluster } from './connection';

const PROVIDER_URL = 'https://www.sollet.io';


export class SolletProvider {

    static wallet: Wallet = new Wallet(PROVIDER_URL, ConnectedCluster);
    ;

    static getProvider = async () => {
        if (!SolletProvider.wallet.connected) {
            return SolletProvider.wallet.connect() as Promise<void>;
        } else {
            return Promise.resolve();
        }
    };

    static sendTxUsingExternalSignature = async (
        instructions: TransactionInstruction[],
        connection: Connection,
        feePayer: Account | null,
        signersExceptWallet: Account[]
    ) => {
        let tx = new Transaction().add(...instructions);
        // tx.partialSign(
        //     ...(feePayer
        //         ? [(feePayer as Account).publicKey, SolletProvider.wallet.publicKey]
        //         : [SolletProvider.wallet.publicKey]),
        //     ...signersExceptWallet.map((s) => s.publicKey)
        // );
        tx.recentBlockhash = (await connection.getRecentBlockhash('max')).blockhash;
        signersExceptWallet.forEach((acc) => {
            tx.partialSign(acc);
        });
        let signed = await SolletProvider.wallet.signTransaction(tx);
        let txid = await connection.sendRawTransaction(signed.serialize(), {
            skipPreflight: false,
            preflightCommitment: COMMITMENT,
        });
        return connection.confirmTransaction(txid, COMMITMENT);
    };

}