import { MintInfo, MintLayout } from "@solana/spl-token";
import { PublicKey, TokenAccountsFilter } from "@solana/web3.js";
import axios from "axios";
import BN from "bn.js";
import { CommonConstant, getConnection } from "utility";

export class AccountService {

    static getMintValue = async (addresses: string[]): Promise<number[]> => {
        const mintData = `{
      "jsonrpc": "2.0",
      "id": 1,
      "method": "getMultipleAccounts",
      "params": [
        ${JSON.stringify(addresses)}
      ]
  }`;

        const mintResponse: any = await axios({
            method: 'POST',
            url: CommonConstant.solanaAddress,
            data: mintData,
            headers: { 'content-type': 'application/json' },
        });

        return mintResponse.data.result.value.map((t: any) => {
            const tempState = Buffer.from(
                Uint8Array.from(atob(t.data[0]), (c: any) => c.charCodeAt(0))
            );
            const j = (MintLayout.decode(
                tempState
            ) as MintInfo);
            return new BN(j.supply, 10, 'le').toNumber();
        })

    }

    static getBalance = async (owner: string) => {
        const data = `{
          "jsonrpc": "2.0",
          "id": 1,
          "method": "getTokenAccountBalance",
          "params": [
            "${owner}"
          ]
      }`;

        const response: any = await axios({
            method: 'POST',
            url: CommonConstant.solanaAddress,
            data: data,
            headers: { 'content-type': 'application/json' },
        });
        console.log(response);

        const balance = response.data.result.value.uiAmount;
        return balance;
    };

    static getStakeAddress = async (
        owner: string,
        stake_mint: string
    ) => {
        const ownerPubKey = new PublicKey(owner);
        const mintPubKey = new PublicKey(stake_mint);

        const connection = await getConnection();

        const filters: TokenAccountsFilter = {
            mint: mintPubKey,
        } as TokenAccountsFilter;
        const accounts = await connection.getTokenAccountsByOwner(
            ownerPubKey,
            filters
        );
        console.log(accounts);

        return accounts.value.map((account) => account.pubkey.toBase58());
    };

}

