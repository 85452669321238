import { Account, Connection, Transaction, TransactionInstruction } from "@solana/web3.js";
import { IPhantomProvider } from "models";
import { COMMITMENT } from "./connection";

export class PhantomProvider {

    static ConnectedProvider: IPhantomProvider | undefined;

    static getProvider = (): IPhantomProvider | undefined => {
        if ("solana" in window) {
            const provider = (window as any).solana;
            if (provider.isPhantom) {
                return provider;
            }
        }
        window.open("https://phantom.app/", "_blank");
    };

    static sendTxUsingExternalSignature = async (
        instructions: TransactionInstruction[],
        connection: Connection,
        feePayer: Account | null,
        signersExceptWallet: Account[],
    ) => {
        let tx = new Transaction().add(...instructions);
        const publicKey = (PhantomProvider.ConnectedProvider as IPhantomProvider).publicKey

        tx.setSigners(
            ...(feePayer
                ? [(feePayer as Account).publicKey, publicKey]
                : [publicKey]),
            ...signersExceptWallet.map((s) => s.publicKey)
        );
        tx.recentBlockhash = (await connection.getRecentBlockhash('max')).blockhash;
        signersExceptWallet.forEach((acc) => {
            tx.partialSign(acc);
        });
        let signed = await (PhantomProvider.ConnectedProvider as IPhantomProvider).signTransaction(tx);
        let txid = await connection.sendRawTransaction(signed.serialize(), {
            skipPreflight: false,
            preflightCommitment: COMMITMENT,
        });
        return connection.confirmTransaction(txid, COMMITMENT);
    };
}