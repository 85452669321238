import { useMemo } from "react";
import { Placeholder, Card } from "semantic-ui-react";

interface IProps {
    size: number;
}

export const AppPlaceHolder = (props: IProps) => {

    const lines = useMemo(() => Array.from({ length: props.size }, (_, k) => k), [props.size || 16]);

    return (
        <>
            <Card.Group doubling itemsPerRow={4} stackable>
                {lines.map((line) => (
                    <Card key={line}>
                        <Placeholder>
                            <Placeholder.Image square />
                        </Placeholder>
                        <Card.Content>
                            <Placeholder>
                                <Placeholder.Header>
                                    <Placeholder.Line length='very short' />
                                    <Placeholder.Line length='very long' />
                                </Placeholder.Header>
                                <Placeholder.Paragraph>
                                    <Placeholder.Line length='medium' />
                                </Placeholder.Paragraph>
                            </Placeholder>
                        </Card.Content>
                    </Card>
                ))}
            </Card.Group>
        </>
    )

}