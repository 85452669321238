import { Button } from "semantic-ui-react";
import styled from "styled-components";

export const PrimaryButton = styled(Button)`
    background-color : ${({ theme }) => theme.palette.primary} !important; 
    color : ${({ theme }) => theme.palette.text} !important;
`

export const DangerButton = styled(Button)`
    background-color : ${({ theme }) => theme.palette.danger} !important; 
`