import { PrimaryButton } from "elements"
import { Form } from "semantic-ui-react"
import styled from "styled-components"
import { WalletNotConnectedError } from "@solana/wallet-adapter-base"
import { useConnection, useWallet } from "@solana/wallet-adapter-react"
import { Keypair, SystemProgram, Transaction } from "@solana/web3.js"
import { useState } from "react"
import { IMarketplace } from "models"
import { MarketplaceService } from "utility"
import { useHistory } from "react-router-dom"

const Header = styled.p`
    color : ${({ theme }) => theme.palette.text};
    font-size : 1.5rem;
    font-wieght : bold;
`

const AppForm = styled(Form)`
    label {
        color : ${({ theme }) => theme.palette.text} !important;
    }
`

export const AddNFTScreen = () => {

    const [formData, setFormData] = useState<Partial<IMarketplace>>({
        title: '',
        price: 0,
        address: '',
        downpayment: 0,
        duration: 0
    });
    let history = useHistory();

    const { connection } = useConnection();
    const { publicKey, sendTransaction, connected } = useWallet();

    const onSubmit = async () => {

        await MarketplaceService.add(formData);

        if (!publicKey) throw new WalletNotConnectedError();

        const transaction = new Transaction().add(
            SystemProgram.transfer({
                fromPubkey: publicKey,
                toPubkey: Keypair.generate().publicKey,
                lamports: 1,
            })
        );
        const signature = await sendTransaction(transaction, connection);
        await connection.confirmTransaction(signature, 'processed');
        history.push("/home");
    };

    const handleChange = (name: string, value: string | number) => {
        setFormData({
            ...formData,
            [name]: value
        })
    }

    return (
        <div className="container">
            <div className="row my-3">
                <div className="col-12">
                    <Header>Add Your NFT</Header>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <AppForm onSubmit={onSubmit}>
                        <Form.Field >
                            <label>NFT Address</label>
                            <input
                                placeholder='Your NFT Address'
                                value={formData.address}
                                onChange={(event) => handleChange("address", event.target.value)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Title</label>
                            <input
                                placeholder='Title'
                                value={formData.title}
                                onChange={(event) => handleChange("title", event.target.value)}
                            />
                        </Form.Field>
                        <Form.Group widths="equal">
                            <Form.Field>
                                <label>Amount</label>
                                <input
                                    placeholder='Amount'
                                    type="number"
                                    value={formData.price}
                                    onChange={(event) => handleChange("price", event.target.value)}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Duration(days)</label>
                                <input
                                    placeholder='Duration'
                                    type="number"
                                    value={formData.duration}
                                    onChange={(event) => handleChange("duration", event.target.value)}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Downpayment(%)</label>
                                <input
                                    placeholder='Downpayment'
                                    type="number"
                                    value={formData.downpayment}
                                    onChange={(event) => handleChange("downpayment", event.target.value)}
                                />
                            </Form.Field>
                        </Form.Group>
                        <PrimaryButton type='submit' size="large">Submit</PrimaryButton>
                    </AppForm>
                </div>
            </div>
        </div>
    )
}