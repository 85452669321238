import {
  Cluster,
  clusterApiUrl,
  Commitment,
  Connection
} from "@solana/web3.js";
import { CommonConstant } from "./common-constant";

type Localnet = "localnet";

export const COMMITMENT: Commitment = "singleGossip";

export const CLUSTERS = {
  MAINNET: "mainnet-beta" as Cluster,
  TESTNET: "testnet" as Cluster,
  DEVNET: "devnet" as Cluster,
  LOCALNET: "localnet" as Localnet
};

export const NETWORK = clusterApiUrl((CommonConstant.clusterUser || CLUSTERS.MAINNET) as Cluster);

export const ConnectedCluster = ((CommonConstant.clusterUser || CLUSTERS.MAINNET) as Cluster) as Cluster | Localnet;

let connection: Connection;

export const connect = (clusterName: Cluster | Localnet = CommonConstant.clusterUser as Cluster) => {
  return new Connection(
    clusterName === CLUSTERS.LOCALNET
      ? "http://localhost:8899"
      : clusterApiUrl(clusterName),
    COMMITMENT
  );
};

export const getConnection = () => {
  if (!connection) {
    connection = connect();
  }
  return connection;
};