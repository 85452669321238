import { IMarketplace } from "models"
import { useEffect, useState } from "react"
import { MarketplaceService } from "utility";

export const MarketplaceHook = () => {
    const [data, setData] = useState<IMarketplace[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const result = await MarketplaceService.get();
                setData(result);
            } catch (error) {

            } finally {
                setLoading(false);
            }
        }

        fetchData();
    }, []);

    return { data, loading };
}

export const WalletListHook = () => {
    const [data, setData] = useState<IMarketplace[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const result = await MarketplaceService.get();
                setData(result.filter(x => x.id < 4));
            } catch (error) {

            } finally {
                setLoading(false);
            }
        }

        fetchData();
    }, []);

    return { data, loading };
}

export const MarketplaceByIdHook = (id: string) => {
    const [data, setData] = useState<IMarketplace | undefined>();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const result = await MarketplaceService.getById(+id);
                setData(result);
            } catch (error) {

            } finally {
                setLoading(false);
            }
        }

        if (id) {
            fetchData();
        }

    }, [id]);

    return { data, loading };
}