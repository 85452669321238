import { Account, Connection, TransactionInstruction } from "@solana/web3.js";
import { IPhantomProvider } from "models";
import { SolletProvider, Store } from "utility";
import { PhantomProvider } from "./phantom-provider";

export class Provider {

    static getPublicKey = () => {
        if (Store.state.connectedWalletType === "sollet") {
            return SolletProvider.wallet.publicKey;
        }
        return (PhantomProvider.ConnectedProvider as IPhantomProvider).publicKey;
    }

    static sendTxUsingExternalSignature = async (
        instructions: TransactionInstruction[],
        connection: Connection,
        feePayer: Account | null,
        signersExceptWallet: Account[],
    ) => {
        // if (store.state.connectedWalletType === "sollet") {
        //     return SolletProvider.sendTxUsingExternalSignature(instructions, connection, feePayer, signersExceptWallet);
        // }
        return PhantomProvider.sendTxUsingExternalSignature(instructions, connection, feePayer, signersExceptWallet);
    };
}