import { IMarketplace } from "models";

import i1 from "images/1.jpeg";
import i2 from "images/2.jpeg";
import i3 from "images/3.jpeg";
import i4 from "images/4.jpeg";
import i5 from "images/5.jpeg";
import i6 from "images/6.png";
import i7 from "images/7.png";
import i8 from "images/8.png";

function timeout(ms: number = 1500) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export class MarketplaceService {

    static data: IMarketplace[] = [{
        id: 1,
        title: "2205 - Bored Ape Yacht Club",
        address: "0xffbf315f70e458e49229654dea4ce192d26f9b25",
        "author": "Shlokz",
        price: 100,
        image: i1,
        duration: 10,
        like: 1,
        downpayment: 20
    }, {
        id: 2,
        title: "Alien #4747",
        address: "0xffbf315f70e458e49229654dea4ce192d16f9b25",
        "author": "Shlokz",
        price: 200,
        duration: 20,
        like: 10,
        downpayment: 2,
        image: i2
    }, {
        id: 3,
        title: "500 - Bored Ape Yacht Club",
        address: "0xffbf315f70e458e49229654dea4ce192d26f9b55",
        "author": "Shlokz",
        price: 5,
        duration: 2,
        like: 100,
        downpayment: 20,
        image: i3
    }, {
        id: 4,
        title: "345 - Bored Ape Yacht Club",
        address: "0xffbf315f70e458e49229654dea4ce192d26f9bdd",
        "author": "Shlokz",
        price: 23,
        duration: 10,
        like: 1,
        downpayment: 20,
        image: i4
    }, {
        id: 5,
        title: "2205 - Bored Ape Yacht Club",
        address: "0xffbf315f70e458e49229654dea4ce192d26f9b25",
        "author": "Shlokz",
        price: 100,
        duration: 10,
        like: 1,
        downpayment: 22,
        image: i5
    }, {
        id: 6,
        title: "Alien #4747",
        address: "0xffbf315f70e458e49229654dea4ce192d16f9b25",
        "author": "Shlokz",
        price: 200,
        duration: 10,
        like: 1,
        downpayment: 43,
        image: i6
    }, {
        id: 7,
        title: "500 - Bored Ape Yacht Club",
        address: "0xffbf315f70e458e49229654dea4ce192d26f9b55",
        "author": "Shlokz",
        price: 5,
        duration: 43,
        like: 77,
        downpayment: 50,
        image: i7
    }, {
        id: 8,
        title: "345 - Bored Ape Yacht Club",
        address: "0xffbf315f70e458e49229654dea4ce192d26f9bdd",
        "author": "Shlokz",
        price: 23,
        duration: 43,
        like: 77,
        downpayment: 50,
        image: i8
    }, {
        id: 9,
        title: "2205 - Bored Ape Yacht Club",
        address: "0xffbf315f70e458e49229654dea4ce192d26f9b25",
        "author": "Shlokz",
        price: 100,
        duration: 43,
        like: 77,
        downpayment: 50,
        image: i1
    }, {
        id: 10,
        title: "Alien #4747",
        address: "0xffbf315f70e458e49229654dea4ce192d16f9b25",
        "author": "Shlokz",
        price: 200,
        duration: 43,
        like: 77,
        downpayment: 50,
        image: i2
    }, {
        id: 11,
        title: "500 - Bored Ape Yacht Club",
        address: "0xffbf315f70e458e49229654dea4ce192d26f9b55",
        "author": "Shlokz",
        price: 5,
        duration: 43,
        like: 77,
        downpayment: 50,
        image: i3
    }, {
        id: 12,
        title: "345 - Bored Ape Yacht Club",
        address: "0xffbf315f70e458e49229654dea4ce192d26f9bdd",
        "author": "Shlokz",
        price: 23,
        duration: 43,
        like: 77,
        downpayment: 50,
        image: i4
    }, {
        id: 13,
        title: "2205 - Bored Ape Yacht Club",
        address: "0xffbf315f70e458e49229654dea4ce192d26f9b25",
        "author": "Shlokz",
        price: 100,
        duration: 43,
        like: 77,
        downpayment: 50,
        image: i5
    }, {
        id: 14,
        title: "Alien #4747",
        address: "0xffbf315f70e458e49229654dea4ce192d16f9b25",
        "author": "Shlokz",
        price: 200,
        duration: 43,
        like: 77,
        downpayment: 50,
        image: i6
    }, {
        id: 15,
        title: "500 - Bored Ape Yacht Club",
        address: "0xffbf315f70e458e49229654dea4ce192d26f9b55",
        "author": "Shlokz",
        price: 5,
        duration: 43,
        like: 77,
        downpayment: 50,
        image: i7
    }, {
        id: 16,
        title: "345 - Bored Ape Yacht Club",
        address: "0xffbf315f70e458e49229654dea4ce192d26f9bdd",
        "author": "Hardik",
        price: 23,
        duration: 43,
        like: 77,
        downpayment: 50,
        image: i8
    }];

    static get = async (): Promise<IMarketplace[]> => {
        await timeout();
        return MarketplaceService.data;
    }

    static add = async (data: Partial<IMarketplace>) => {
        await timeout(1);
        const temp: IMarketplace = {
            id: MarketplaceService.data.length + 1,
            title: data.title || "",
            address: data.address || "",
            "author": "Shlokz",
            price: data.price || 0,
            duration: data.duration || 0,
            like: 0,
            downpayment: data?.downpayment || 0,
            image: i8
        }
        MarketplaceService.data.unshift(temp);
    }

    static getById = async (id: number): Promise<IMarketplace | undefined> => {
        await timeout();
        return MarketplaceService.data.find(x => x.id === id);
    }
}