import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { useWalletModal, WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Header, Icon, Menu, Modal } from "semantic-ui-react";
import styled from "styled-components";

const MenuLink = styled(Link)`
    color : ${({ theme }) => theme.palette.text};

    &:hover{
        color : ${({ theme }) => theme.palette.text};
        font-weight : bold;
        text-decoration : none;
    }
`

const BalanceText = styled.span`
    font-weight : bold;
    font-size :1.2rem;
    color : ${({ theme }) => theme.palette.text};
`

const ModalContainer = styled.div`
    display : flex;
    flex-direction : column;
    height : 100%;
    align-items : center;
    justify-content : center;
    color : ${({ theme }) => theme.palette.text};
     
    i {
    color : ${({ theme }) => theme.palette.text};
    }
`

export const HeaderBar = () => {

    const { connection } = useConnection();
    const { publicKey, connected } = useWallet();
    const modal = useWalletModal();
    const [balance, setBalance] = useState(0);

    useEffect(() => {
        if (publicKey) {
            const fetch = async () => {
                const result = await connection.getBalance(publicKey);
                setBalance(result);
            }

            fetch();
        }
    }, [connection, publicKey]);

    useEffect(() => {
        modal.setVisible(!connected);
    }, [connected, modal.visible]);

    return (
        <>
            <Menu secondary className="px-3 py-3 ">
                <Menu.Item>
                    <MenuLink to="/add-nft">
                        Add NFT
                    </MenuLink>
                </Menu.Item>
                <Menu.Item>
                    <MenuLink to="/">
                        Marketplace
                    </MenuLink>
                </Menu.Item>
                <Menu.Item>
                    <MenuLink to="/wallets">
                        Wallet
                    </MenuLink>
                </Menu.Item>
                <Menu.Menu position='right'>
                    {balance > 0 && <Menu.Item>
                        <BalanceText>
                            Balance: {balance} SOL
                        </BalanceText>
                    </Menu.Item>}
                    <Menu.Item>
                        <WalletMultiButton />
                    </Menu.Item>
                </Menu.Menu>
            </Menu>
            <Modal
                basic
                open={connected}
                size='fullscreen'
            >
                <ModalContainer>
                    <Header icon>
                        <Icon name='lock' />
                    </Header>
                    <Modal.Content>
                        <p>
                            Please contact admin for test token
                        </p>
                    </Modal.Content>
                </ModalContainer>
            </Modal>
        </>
    )
}