import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { AddNFTScreen, HomeScreen, DetailsScreen, WalletScreen } from "screens";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import { defaultTheme } from "theme";
import { ToastContainer } from 'react-toastify';
import { useMemo } from 'react';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import {
  getLedgerWallet,
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletExtensionWallet,
  getSolletWallet,
  getTorusWallet,
} from '@solana/wallet-adapter-wallets';
import { clusterApiUrl } from '@solana/web3.js';
import { HeaderBar } from "layouts";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
require('@solana/wallet-adapter-react-ui/styles.css');

const GlobalStyle = createGlobalStyle`
  html,
  body {
    margin: 0;
    font-family: 'Sora', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    font-style: normal;
    color : ${({ theme }) => theme.palette.text};
    background-color : ${({ theme }) => theme.palette.background};
  }
  .text-right{
	  text-align : right;
  }
`;

function App() {

  // Can be set to 'devnet', 'testnet', or 'mainnet-beta'
  const network = WalletAdapterNetwork.Devnet;

  // You can also provide a custom RPC endpoint
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);

  // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking --
  // Only the wallets you configure here will be compiled into your application
  const wallets = useMemo(() => [
    getPhantomWallet(),
    getSlopeWallet(),
    getSolflareWallet(),
    getTorusWallet({
      options: { clientId: 'Get a client ID @ https://developer.tor.us' }
    }),
    getLedgerWallet(),
    getSolletWallet({ network }),
    getSolletExtensionWallet({ network }),
  ], [network]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyle />
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect={true}>
          <WalletModalProvider>
            <Router>
              <HeaderBar />
              <Switch>
                <Route exact path="/home">
                  <HomeScreen />
                </Route>
                <Route exact path="/wallets">
                  <WalletScreen />
                </Route>
                <Route exact path="/details/:id">
                  <DetailsScreen />
                </Route>
                <Route exact path="/add-nft">
                  <AddNFTScreen />
                </Route>
                <Route path="*">
                  <Redirect to="/add-nft" />
                </Route>
              </Switch>
            </Router>
          </WalletModalProvider>
        </WalletProvider>
      </ConnectionProvider>
      <ToastContainer />
    </ThemeProvider >
  );
}

export default App;
